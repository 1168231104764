@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,700&display=swap');


:root {
  --ebony: #22262A;
  --darkYellow: #FEDA15;
  --forestGreen: #19342D;
  --mistyBlue: #BFD0DA;
}

* {
  box-sizing: border-box;
}

body, html {
  font-family: 'Open Sans', sans-serif;
  color: var(--ebony);
  background-color: var(--mistyBlue);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-weight: 200;
  // scroll-behavior: smooth;
}

body {
  position: relative
}


h1, h2, h3, h4 {
  font-family: 'Playfair Display', serif;
  color: var(--forestGreen);
}

h1 {
  font-size: 50px;
}

h2 {
  font-size: 35px; 
  margin-bottom: 5px;
}

h3 {
  font-size: 30px; 
}

h4 {
  font-size: 20px;
}

p {
  font-size: 18px;
}

header {
  font-family: 'Playfair Display', serif;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.nomargin{
  margin: 0;
  padding: 0;
}

.navigation{
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;


  .logo {
    width: 70px;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      width: 100%;
      margin: 0 15px;
    }

    .active {
      border-top: none;
    }
  }

  .hamburger-react {
    display: none;
  }



  ul{
    height: 100%;
    box-sizing: border-box;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    margin: 0;

    div {
      display: flex;
      align-items: center;
    }
  }

  // li {
  //   font-weight: 300;
  //   font-size: 25px;
  //   padding: 0 15px;
  //   display: flex;
  //   justify-content: center;
  //   height: 100%;
  //   cursor: pointer;
  // }

  // a {
  //   text-decoration: none;
  //   color: var(--ebony);
  //   transition: all .1s linear;
  //   border-top: 0px solid rgb(162, 162, 162);
  //   height: 100%;
  //   padding: 10px 0;
  // }

  li {
    font-weight: 300;
    font-size: 25px;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    color: var(--ebony);
    transition: all .1s linear;
    border-top: 0px solid rgb(162, 162, 162);
  }

  a {
    text-decoration: none;
    color: var(--ebony);
    height: 100%;
    display: flex;
    align-items: center;
    // padding: 10px 0;
    // .active {
    //   border-top: none;
    // }

  }

  a.active, a.active:hover {
    border-top: none
  }

  .active, .active:hover {
    border-top: 4px solid var(--darkYellow);
  }

  ul div:hover {
    border-top: 4px solid rgb(162, 162, 162);
  }

}

.blur {
  position: absolute;
  backdrop-filter: blur(5px);
  width: 100%;
  height: 60px;
  z-index: -1;
}

button {
  background-color: inherit;
  border: 1px solid var(--ebony);
  color: var(--ebony);
  font-family: inherit;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: block;
  width: min-content;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  outline: none;
}


button:hover {
  color: var(--forestGreen);
  background-color: var(--darkYellow); 
}


.green:hover {
  background-color: var(--forestGreen);
  opacity: 0.7;
  color: var(--mistyBlue);
}


.podcast-button {
  border: 1px solid var(--darkYellow);
}


.nacked {
  border: none;
  text-transform: uppercase;
}


.hero {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .move {
    width: 100%;
  }
  
  p {
    margin: -10px 0 0 0;
    text-transform: uppercase;
  }
  
  a {
    text-decoration: none;
    color: var(--ebony);
  }

  .nacked {
    font-size: 30px;
  }
}

.nacked:hover {
  background-color: var(--mistyBlue);
  font-weight: bold;
}

.nacked.active {
  border: none;
  font-weight: bold;
}


.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .description {
    max-width: 700px;
  }
}


#about .container {
  flex-wrap: wrap-reverse;
}


#about, #projects {
  padding: 100px 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  
  div {
    padding: 20px;

    .nomargin{
      margin: 0;
      padding: 0;
    }

  }

  .intro {
    line-height: 1.7;
    max-width: 500px;
  }
}


#about { 
  img {

    max-width: 300px;
    border-radius: 50%;
  }
}




#skills {
  padding: 100px 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  
  .skills {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;    
    font-size: 40px;
    max-width: 500px;

    div {
      color: var(--forestGreen);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5px;
 
      p {
        position: absolute;
        font-weight: 400;
        font-size: 20px;
        opacity: 0;
        max-width: 150px;
        text-align: center;
        margin: auto;
      }
    }

    div:hover {
      svg {
        color: var(--darkYellow);
        opacity: 0.7;
      }
      
      p {
        opacity: 1;
      }
    }
  }

  div {
    padding: 20px;
  }

  .intro {
    line-height: 1.7;
    max-width: 500px;
  }
}


#projects { 
  
  .selection {
    display: flex;
    flex-direction: column;

    div {
      padding: 0;
    }

    svg {
      margin: 0 5px;
      font-size: 25px;
    }

    button {
      padding: 5px;
    }
  }
  
  .options {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 0;
  }

  .techProjects  {
    padding: 0;
  }

  .closed {
    width: 0;
    opacity: 0;
  }

  .open {
    width: initial;
    opacity: 1;
  }



  img {
    max-width: 500px;
    border-radius: 0px;
  }

  .bizProjects {    
    img {
      max-width: 400px;
      box-shadow: 5px 10px 8px #888888;
    }
  }

  h3 {
    margin-bottom: 10px;
  }

  h5 {
    margin: 5px 0;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    button {
      margin: 0 10px;
    }

    a {
      text-decoration: none;
      cursor: pointer;
    }
  }

  .icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-size: 30px;

    div {
      padding: 0 5px;
      color: var(--forestGreen);
      display: flex;
      align-items: center;
      justify-content: center;
      
      p {
        position: absolute;
        font-weight: 400;
        font-size: 15px;
        opacity: 0;
        max-width: 100px;
        text-align: center;
        margin: auto;
      }
    }

    div:hover {
      svg {
        color: var(--darkYellow);
        opacity: 0.7;
      }
      
      p {
        opacity: 1;
      }
    }

  }
}


#contact {
  padding: 100px 30px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-bottom: 20px;
  }

  .contact, .contact form {
    border: var(--ebony) 1px solid;
    transition: all .5s ease-in-out;
    opacity: 0;
    height: 0;

    div, button {
      display: none;
    }

  }

  .open, .open form {
    opacity: 1;
    height: 400px;
    margin-bottom: 3rem;
    transition: all .5s ease-in-out;

    div, button {
      display: initial;
    }
  }


  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    padding: 20px;
    justify-content: center;

    div {
      padding: 5px;
      font-size: 18px;
      width: 95%;
    }

    input, textarea {
      box-sizing: border-box;
      background-color: var(--mistyBlue);
      border: none;
      border-bottom: 1px solid var(--ebony);
      width: 100%;
      padding: 12px 10px;
      font-family: 'Open Sans', sans-serif;
    }

    textarea {
      border-left: 1px solid var(--ebony);
      }
    input:focus, textarea:focus {
      border: 2px solid var(--ebony);
    }
  }  

  .icons {
    font-size: 60px;
    margin: 20px;
    display: flex;
    flex-direction: row;
    width: 500px;
    justify-content: space-evenly;
    // align-items: stretch;
    
    div {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        // margin: 0 20px;
        cursor: pointer;
        
        svg {
          color: var(--forestGreen);
        }

        p {
          position: absolute;
          font-weight: 400;
          font-size: 20px;
          opacity: 0;
          max-width: 100%;
          text-align: center;
          margin: auto;
        }
      }
    }

    div:hover {
      a {

        svg {
          color: var(--darkYellow);
          opacity: 0.7;
        }
        p {
          opacity: 1;
          color: var(--forestGreen);
        }
      }
      

    }
  }

}


iframe {
  .css-cpn8dn {
    background: red;
  }

  border-radius: 2px;
}


#podcast {
  padding: 100px 50px;
  max-width: 1200px;

  .title {
    margin-bottom: 50px;
  }
  img {
    max-width: 300px;
  }
  ul {
    list-style-position: inside;
    list-style-type: none;
    li {
      line-height: 1.5;
    }
  }
  .odesliFrame {
    margin-bottom: 50px;
  }
  .anchorDiv {
    width: 600px;
    margin: auto;

    .anchorPlayer {
      text-align: center;
      height: 100px;
      width: 500px;
      margin: 10px;
    }  
  }
}




//* RESPONSIVE BEHAVIOUR

//TABLETS
@media only screen and (max-width: 900px) {

  .navigation {
  
    .hamburger-react {
      margin: 0 15px;
      display: block;
    }

    .hide {
      display: none;
    }

    .blur {
      backdrop-filter: none;
    }

    ul {
      position: absolute;
      right: 0;
      margin: 50px 0;
      width: 20%;
      list-style-type: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      padding-right: 15px;

      li {
        padding: 5px 15px;
        backdrop-filter: blur(8px);
      }



    }

    // a {
    //   border-right: 0px solid rgb(162, 162, 162);
    //   padding: 0 5px;
    // }

    //  {
    //   border-top: none;
    // //   border-right: 3px solid rgb(162, 162, 162);
    // }
    
    
    a:hover, a.active {
      border-top: none;
      border-right: none;
      padding-right: 0;
    }

    .active, .active:hover {
      padding-right: 5px;
      border-top: none;
      border-right: 3px solid var(--darkYellow);
    }



  }

  .container {
    flex-wrap: wrap;
    max-width: 800px;
  }

}

  
//PHONES
@media only screen and (max-width: 500px) {

  .hero {
    
    .move img {
     width: 350px;
    } 
    p {
      font-size: 15px;
      width: 200px;
      margin-top: 10px;
    }
  }


  #about {
    
    .container {
      width: 100%;

      .intro {
        width: 80%;
      }
    }
  }


  #skills .skills {
    width: 80%;
  }

  #projects 

    .container {
    flex-wrap: wrap;
    }
    .image {
      padding-bottom: 0;
    }
    img {
      width: 90%;
    }

    .description {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }

    h3 {
      margin-top: -30px;
    }

  .contact {
    width: 80%;
  }

  #projects .icons {
    width: 100%;
    div {
      margin: 0px px;
    }
  }

  #contact {

    .icons {
      width: 80%;
    } 
    
    form {
      width: 100%;
      margin: 0;

      input, textarea {
        width: 70%;
      }
    }
  }

  #podcast {

    .intro, h2 {
      width: 300px;
      margin: auto;
    }

    .odesliFrame, .anchorDiv {
      width: 300px;
      margin: auto;

      .anchorPlayer {
        width: 300px;
        margin: auto;
      }
    }   
  }
}


//* ANIMATION

// @keyframes example {
//   0%   {
//     width: 100%; 
//     left: 50%;
//     top: 50%;
//   }
//   25%   {
//     width: 70%; 
//     left: 30%;
//     top: 30%;
//   }

//   75%   {
//     width: 40%; 
//     left: 15%;
//     top: 15%;
//   }

//   100%   {
//     width: 0%; 
//     left: 0;
//     top: 0;
//   }

// }

/* The element to apply the animation to */
// .move {
  // display: block;
  // left: 0;
  // top: 0;
  // width: 100%;
  // height: 100px;
  // position: relative;
  // background-color: red;
  // animation-name: example;
  // animation-duration: 4s;
  // animation-timing-function: linear;
  // animation-fill-mode: forwards;
// }

// @import url('locomotive-scroll.css');